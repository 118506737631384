<template>
    <div class="main">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <div class="col-12">
                        <h5>{{ activeLanguage.totalorders_title }}</h5>
                    </div>
                    <total-orders-bar />
                </div>
                <div class="col-6">
                    <div class="col-12">
                        <h5>{{ activeLanguage.accountmanager_title }}</h5>
                    </div>
                    <orders-account-bar />
                </div>
            </div>
            <div class="col-12" style="margin-top: 50px;">
                <div class="col-12">
                    <h5>{{ activeLanguage.totalorders_24months_title }}</h5>
                </div>
                <total-orders-line />
            </div>
            <div class="col-12" style="margin-top: 50px;">
                <div class="col-12">
                    <h5>{{ activeLanguage.order_per_platform_3months_title }}</h5>
                </div>
                <total-orders-platform-bar />
            </div>
        </div>        
    </div>                
</template>

<script>
import TotalOrdersBar from '../components/statisticsEmployee/TotalOrdersBar.vue'
import OrdersAccountBar from '../components/statisticsEmployee/OrdersAccountBar.vue'
import TotalOrdersLine from '../components/statisticsEmployee/TotalOrdersLine.vue'
import TotalOrdersPlatformBar from '../components/statisticsEmployee/TotalOrdersPlatformBar.vue'

import { mapGetters } from 'vuex'
export default {
    data() {
        return {
        }
    },
    components: {
        TotalOrdersBar,
        OrdersAccountBar,
        TotalOrdersLine,
        TotalOrdersPlatformBar
    },
    computed: {
        ...mapGetters({
            activeLanguage: 'getStatisticsEmployeeLanguage'
        })
    }
}
</script>

<style lang="scss" scoped>

</style>