<template>
    <div>
      <div class="row">
            <loading-statistic :active="isLoading"
                :canCancel="true"></loading-statistic>
      </div>
      <mdb-container>
          <mdb-horizontal-bar-chart
          :data="horizontalBarChartData"
          :options="horizontalBarChartOptions"
          :width="500"
          :height="250"
          ></mdb-horizontal-bar-chart>
      </mdb-container>
    </div>
</template>

<script>
import { mdbHorizontalBarChart, mdbContainer } from "mdbvue";
import Loading from '../statisticsView/Loading.vue'

const MONTH_LIST = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]

export default {
    components: {
      mdbHorizontalBarChart,
      mdbContainer,
      'loading-statistic': Loading
    },
    mounted() {
      this.getTotalOrders()
    },
    methods: {
      getTotalOrders() {
            newbillinkAxios.get('/employee-statistics/total-orders')
                .then(({ data }) => {
                    this.totalorders = data.last_3_months_orders
                    this.horizontalBarChartData.datasets[0].data = [
                        parseInt(this.totalorders.before_prev_month),
                        parseInt(this.totalorders.prev_month),
                        parseInt(this.totalorders.current_month)
                    ]
                    this.$children[1].$children[0].renderChart(this.horizontalBarChartData, this.horizontalBarChartOptions)
                    this.isLoading = true
                    setTimeout(() => {
                      this.isLoading = false
                    }, 500)
                }).catch(err => { 
                    console.log(err)
                })
        }
    },
    data() {
      return {
        totalorders: {},
        isLoading: false,
        horizontalBarChartData: {
          labels: [
            MONTH_LIST[new Date().getMonth() - 2],
            MONTH_LIST[new Date().getMonth() - 1],
            MONTH_LIST[new Date().getMonth()]
          ],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "rgba(255, 206, 86, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(255, 206, 86, 0.2)"
              ],
              borderColor: [
                "rgba(255, 206, 86, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(255, 206, 86, 1)"
              ],
              borderWidth: 1
            }
          ]
        },
        horizontalBarChartOptions: {
          legend: {
              display: false
          },
          responsive: false,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                    beginAtZero: true,
                    suggestedMin: 0
                },
                barPercentage: 1,
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.1)"
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.1)"
                }
              }
            ]
          },
          tooltips: {
              enabled: true,
              callbacks: {
                  label: ((tooltipItems, data)=> {
                      return `Orders: ${tooltipItems.xLabel}`
                  })
              }
          }
        }
      };
    }
  }
</script>