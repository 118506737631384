<template>
    <div>
       <div class="row">
            <loading-statistic :active="isLoading"
                :canCancel="true"></loading-statistic>
      </div>
      <mdb-container>
        <mdb-horizontal-bar-chart
          :data="horizontalBarChartData"
          :options="horizontalBarChartOptions"
          :width="1118"
          :height="350"
          ></mdb-horizontal-bar-chart>
      </mdb-container>
    </div>
</template>

<script>
import { mdbHorizontalBarChart, mdbContainer } from "mdbvue";
import Loading from '../statisticsView/Loading.vue'
import Helpers from '../../helpers/modules/scripts'

const MONTH_LIST = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]

export default {
    components: {
      mdbHorizontalBarChart,
      mdbContainer,
      'loading-statistic': Loading
    },
    mounted() {
        this.getOrderPerPlatform()
    },
    methods: {
      getOrderPerPlatform() {
        newbillinkAxios.get('/employee-statistics/orders_per_platform_3months')
          .then(({ data }) => {
              this.data = data.orders_per_platform
              this.horizontalBarChartData.datasets = this.changeStructureData(this.data)
              this.$children[1].$children[0].renderChart(this.horizontalBarChartData, this.horizontalBarChartOptions)
              this.isLoading = true
              setTimeout(() => {
                this.isLoading = false
              }, 500)
          }).catch(err => { 
              console.log(err) 
          })
      },
      changeStructureData(obj) {
        let newData = []
          for (let [key, value] of Object.entries(obj)) {
              newData.push({
                  label: key.charAt(0).toUpperCase() + key.slice(1),
                  data: [
                    parseInt(value.before_prev_month) || 0,
                    parseInt(value.prev_month) || 0,
                    parseInt(value.current_month) || 0
                  ],
                  backgroundColor: Helpers.getRandomRgb(0.2)
              })
          }
          return newData
      }
    },
    data() {
      return {
        data: {},
        isLoading: false,
        horizontalBarChartData: {
          labels: [
              MONTH_LIST[new Date().getMonth() - 2],
              MONTH_LIST[new Date().getMonth() - 1],
              MONTH_LIST[new Date().getMonth()]
          ],
          datasets: []
        },
        horizontalBarChartOptions: {
          responsive: false,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }]
          },
          tooltips: {
            displayColors: true,
            callbacks:{
                mode: 'x',
            },
          }
        }
      };
    }
  }
</script>