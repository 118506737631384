<template>
    <div>
        <div class="row">
            <loading-statistic :active="isLoading"
                :canCancel="true"></loading-statistic>
        </div>
        <mdb-container>
            <mdb-horizontal-bar-chart
            :data="horizontalBarChartData"
            :options="horizontalBarChartOptions"
            :width="500"
            :height="250"
            ></mdb-horizontal-bar-chart>
        </mdb-container>
    </div>
</template>

<script>
import { mdbHorizontalBarChart, mdbContainer } from "mdbvue";
import Loading from '../statisticsView/Loading.vue'

export default {
    components: {
      mdbHorizontalBarChart,
      mdbContainer,
      'loading-statistic': Loading
    },
    mounted() {
      this.getAccountManager()
    },
    methods: {
      sumTotalOrdersAccount(data) {
            let newData = {}
            for (let [key, value] of Object.entries(data)) {
                newData[key] = this.sumAmountOrders(value)
            }
            return newData
        },
        sumAmountOrders (data) {
            let amount = 0;
            for (let [key, value] of Object.entries(data)) {
                amount += parseInt(value)
            }
            return amount;
        },
        getAccountManager() {
            newbillinkAxios.get('/employee-statistics/account-managers ')
                .then(({ data }) => {
                    this.accountManagers = this.sumTotalOrdersAccount(data.orders_per_account_manager)
                    this.initData(this.sumTotalOrdersAccount(data.orders_per_account_manager))
                    this.$children[1].$children[0].renderChart(this.horizontalBarChartData, this.horizontalBarChartOptions)
                    this.isLoading = true
                    setTimeout(() => {
                      this.isLoading = false
                    }, 500)
                }).catch(err => { 
                    console.log(err)
                })
        },
        initData (obj) {
          for (let [key, value] of Object.entries(obj)) {
            this.horizontalBarChartData.labels.push(key)
            this.horizontalBarChartData.datasets[0].data.push(parseInt(value))
          }
        }
    },
    data() {
      return {
        accountManagers: {},
        isLoading: false,
        horizontalBarChartData: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
              ],
              borderColor: [
                "rgba(255,99,132,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
              ],
              borderWidth: 1
            }
          ]
        },
        horizontalBarChartOptions: {
          legend: {
              display: false
          },
          responsive: false,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                ticks: {
                    beginAtZero: true,
                    suggestedMin: 0
                },
                barPercentage: 1,
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.1)"
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  color: "rgba(0, 0, 0, 0.1)"
                }
              }
            ]
          },
          tooltips: {
              enabled: true,
              callbacks: {
                  label: ((tooltipItems, data)=> {
                      return `Orders: ${tooltipItems.xLabel}`
                  })
              }
          }
        }
      };
    }
  }
</script>