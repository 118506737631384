<template>
    <div>
        <div class="row">
            <loading-statistic :active="isLoading"
                :canCancel="true"></loading-statistic>
        </div>
        <mdb-container>
            <mdb-line-chart
            :data="lineChartData"
            :options="lineChartOptions"
            :width="1118"
            :height="350"
            ></mdb-line-chart>
        </mdb-container>
    </div>
</template>

<script>
import { mdbLineChart, mdbContainer } from "mdbvue";
import Loading from '../statisticsView/Loading.vue'
import Helpers from '../../helpers/modules/scripts'

export default {
    components: {
      mdbLineChart,
      mdbContainer,
      'loading-statistic': Loading
    },
    mounted() {
      this.getTotalOrders24Months()
    },
    methods: {
      getTotalOrders24Months() {
          newbillinkAxios.get('/employee-statistics/total-orders-24months')
            .then(({ data }) => {
                delete data.orders_per_last_24_months[new Date().getFullYear() - 2]
                this.data = data.orders_per_last_24_months
                this.lineChartData.datasets = this.changeStructureData(this.data)
                this.$children[1].$children[0].renderChart(this.lineChartData, this.lineChartOptions)
                this.isLoading = true
                setTimeout(() => {
                  this.isLoading = false
                }, 500)
            }).catch(err => { 
              console.log(err) 
            })
      },
      changeStructureData(data) {
        let newData = []
        for (let [key, value] of Object.entries(data)) {
            newData.push({
               label: parseInt(key),
               data: [
                  parseInt(value.January) || 0,
                  parseInt(value.February) || 0,
                  parseInt(value.March) || 0,
                  parseInt(value.April) || 0,
                  parseInt(value.May) || 0,
                  parseInt(value.June) || 0,
                  parseInt(value.July) || 0,
                  parseInt(value.August) || 0,
                  parseInt(value.September) || 0,
                  parseInt(value.October) || 0,
                  parseInt(value.November) || 0,
                  parseInt(value.December) || 0,
               ],
               backgroundColor: Helpers.getRandomRgb(0.2)
            })
        }
        return newData
      }
    },
    data() {
      return {
        data: {},
        isLoading: false,
        lineChartData: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          datasets: [
          ]
        },
        lineChartOptions: {
          responsive: true,
          tooltips: {
              enabled: true,
              callbacks: {
                  label: ((tooltipItems, data) => {
                      if (tooltipItems.datasetIndex === 0) {
                          return `${tooltipItems.xLabel} ${new Date().getFullYear() - 1}: ${tooltipItems.yLabel}`
                      } else {
                          return `${tooltipItems.xLabel} ${new Date().getFullYear()}: ${tooltipItems.yLabel}`
                      }
                  })
              }
          }
        }
      };
    }
  }
</script>